import Vue from "vue";
import Vuex from "vuex";

//modules

import theme from "./theme";
import auth from "./auth";
import enterprise from "./enterprise";
import country from "./country";
import site from "./site";
import user from "./user";
import deskroom from "./deskroom";
import department from "./department";
import type from "./type";
import stock from "./stock";
import supplier from "./supplier";
import material from "./material";
import agent from "./agent";
import state from "./state";
import accessory from "./accessory";
import inventory from "./inventory";
import convenant from "./convenant";
import characteristic from "./characteristic";
import sector from "./sector";
import supply from "./supply";
import transfer from "./transfer";
import amortizationtype from "./amortizationtype";
import acquisitiontype from "./acquisitiontype";
import outputmaterial from "./outputmaterial";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    theme,
    enterprise,
    country,
    site,
    user,
    acquisitiontype,
    deskroom,
    department,
    type,
    stock,
    supplier,
    material,
    convenant,
    characteristic,
    accessory,
    outputmaterial,
    inventory,
    agent,
    state,
    auth,
    sector,
    supply,
    transfer,
    amortizationtype,
  },
});
