import axios from '../../libs/axios';

export default {
  namespaced: true,

  state: {
    employees: [],
    assigned_materials: [],
    users_form_material_employee: [],
    employeesSeriesMaterials: [],
  },

  getters: {},

  mutations: {
    ADD_EMPLOYEE(state, data) {
      state.employees.data.unshift(data);
    },

    FETCH_EMPLOYEES(state, data) {
      state.employees = data;
    },

    FETCH_USERS_FROM_MATERIAL_EMPLOYEE(state, data) {
      state.users_form_material_employee = data;
    },

    FETCH_EMPLOYEES_MATERIALS(state, data) {
      state.employees_materials = data;
    },

    FETCH_ASSIGNED_MATERIALS(state, data) {
      state.assigned_materials = data;
    },

    FETCH_SERIES_MATERIALS(state, data) {
      state.employeesSeriesMaterials = data;
    },
  },

  actions: {
    createEmployee({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/user/add-employee", formData)
          .then((response) => {
            resolve(response);
            commit("ADD_EMPLOYEE", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateUser(commit, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post("/employee/update/" + formData.get("id"), formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allEmployees: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/employee/allbysite", data)
          .then((response) => {
            commit("FETCH_EMPLOYEES", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    siteEmployeesWithoutOldMaterialEmployee: function ({ commit }, id, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/employee/site-employees-without-old-material-employee/${id}`,
            data
          )
          .then((response) => {
            commit("FETCH_USERS_FROM_MATERIAL_EMPLOYEE", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    setMaterialMoving(commit, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/employee/set-material-moving", formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deskroomEmployees: function ({ commit }, id, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/employee/allbydeskroom/${id}`, data)
          .then((response) => {
            commit("FETCH_EMPLOYEES", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteUser(commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/employee/delete/" + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    userAssignedMaterials({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/employee/" + id + "/materials")
          .then((response) => {
            commit("FETCH_ASSIGNED_MATERIALS", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    showUser(commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/employee/" + id + "/show")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    exportPdf() {
      return new Promise((resolve, reject) => {
        axios
          .get("/employee/export/pdf")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    exportExcel() {
      return new Promise((resolve, reject) => {
        axios
          .get("/employee/export/excel")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    massUsersImport({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/employee/import", formData)
          .then((response) => {
            commit("ADD_EMPLOYEE", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    globalUsersFilter(
      { commit },
      { employeeId, departmentId, deskroomId, searchD, rank }
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/employee/global-filter?employee_id=${employeeId}&department_id=${departmentId}&search=${searchD}&deskroom_id=${deskroomId}&rank=${rank}`
          )
          .then((response) => {
            resolve(response);
            commit("FETCH_EMPLOYEES", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getTrackingFile(commit, reference) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/employee/download-tracking-file/${reference}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    employeeStatisticMaterial({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/employee/materials/statistic")
          .then((response) => {
            commit("FETCH_SERIES_MATERIALS", response.data);
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
