import axios from "../../libs/axios";

export default {
  namespaced: true,

  state: {
    enterprises: [],
    enterprise: {},
    enterpriseInfo: JSON.parse(localStorage.getItem("enterpriseInfo")),
  },

  getters: {},

  mutations: {
    ADD_ENTERPRISE(state, data) {
      state.enterprises.data.push(data);
    },

    FETCH_ENTERPRISES(state, data) {
      state.enterprises = data;
    },

    UPDATE_ENTERPRISE_INFO(state, data) {
      localStorage.setItem("enterpriseInfo", JSON.stringify(data));
      state.enterpriseInfo = data;
    },
  },

  actions: {
    createEnterprise: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/enterprise/create", formData)
          .then((response) => {
            commit("UPDATE_ENTERPRISE_INFO", response.data);
            resolve(response);
            commit("ADD_ENTERPRISE", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateEnterprise({commit}, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post("/enterprise/update/" + formData.get("id"), formData)
          .then((response) => {
            commit("UPDATE_ENTERPRISE_INFO", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allEnterprises: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/enterprise/all", data)
          .then((response) => {
            commit("FETCH_ENTERPRISES", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
