import axios from "../../libs/axios";

export default {
  namespaced: true,

  state: {
    states: [],
    stateMaterials: [],
    statesSeriesMaterials: [],
    state: {},
  },

  getters: {},

  mutations: {
    ADD_STATE(state, data) {
      state.states.data.push(data);
    },

    FETCH_STATES(state, data) {
      state.states = data;
    },

    FETCH_SERIES_MATERIALS(state, data) {
      state.statesSeriesMaterials = data;
    },

    GET_STATE(state, data) {
      state.state = data;
    },

    FETCH_STATE_MATERIALS(state, data) {
      state.stateMaterials = data;
    },
  },

  actions: {
    allStates({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/state/allstates", data)
          .then((response) => {
            commit("FETCH_STATES", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    allStateMaterials({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/material/allbystate/${id}`)
          .then((response) => {
            resolve(response);
            commit("FETCH_STATE_MATERIALS", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    stateFilter({ commit }, search) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/state/research?search=${search}`)
          .then((response) => {
            resolve(response);
            commit("FETCH_STATES", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    createState({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/state/create", formData)
          .then((response) => {
            resolve(response);
            commit("ADD_STATE", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    showState({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/state/show/${id}`)
          .then((response) => {
            resolve(response);
            commit("GET_STATE", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    updateState(commit, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post(`/state/update/${formData.get("id")}`, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteState(commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/state/delete/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    stateStatisticMaterial({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/state/statistic/materials")
          .then((response) => {
            commit("FETCH_SERIES_MATERIALS", response.data);
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
