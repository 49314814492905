import axios from "../../libs/axios";

export default {
  namespaced: true,

  state: {
    user: {},
    token: localStorage.getItem("token"),
    userInfo: JSON.parse(localStorage.getItem("userInfo")),
    enterpriseInfo: JSON.parse(localStorage.getItem("enterpriseInfo")),
  },

  getters: {},

  mutations: {
    UPDATE_USER_INFO(state, data) {
      localStorage.setItem("userInfo", JSON.stringify(data));
      state.userInfo = data;
    },

    INIT_TOKEN(state, value) {
      state.token = value;
      localStorage.setItem("token", value);
    },

    UPDATE_ENTERPRISE_INFO(state, data) {
      localStorage.setItem("enterpriseInfo", JSON.stringify(data));
      state.enterpriseInfo = data;
    },

    LOGOUT(state) {
      state.user = null;
      state.token = null;
      localStorage.removeItem("token");
      localStorage.removeItem("userInfo");
      localStorage.removeItem("siteInfo");
      localStorage.removeItem("enterpriseInfo");
    },
  },

  actions: {

    logout: function ({ commit }) {
      commit("LOGOUT");
    },

    login: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/auth/login", formData)
          .then((response) => {
            commit("UPDATE_USER_INFO", response.data.user);
            commit("INIT_TOKEN", response.data.user.token);
            commit("UPDATE_ENTERPRISE_INFO", response.data.enterprise);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    sendRecoveryEmail: function (commit, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/auth/recovery-email", formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    recoverPassword: function (commit, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/auth/recover-password", formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    register: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/auth/register", formData)
          .then((response) => {
            commit("UPDATE_USER_INFO", response.data);
            commit("INIT_TOKEN", response.data.token);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateAgent({ commit }, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post("/user/update/" + formData.get("id"), formData)
          .then((response) => {
            commit("UPDATE_USER_INFO", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    activeEnterprise({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/user/active-enterprise/" + id)
          .then((response) => {
            commit("UPDATE_ENTERPRISE_INFO", response.data.enterprise);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    showAgent({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/user/show/" + id)
          .then((response) => {
            commit("UPDATE_USER_INFO", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
