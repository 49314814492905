import axios from '../../libs/axios';

export default {
  namespaced: true,

  state: {
    departments: [],
    department: {},
  },

  getters: {},

  mutations: {
    ADD_DEPARTMENT(state, data) {
      state.departments.data.push(data);
    },

    FETCH_DEPARTEMENTS(state, data) {
      state.departments = data;
    },

    GET_DEPARTMENT(state, data) {
      state.department = data;
    },
  },

  actions: {
    createDepartment: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/department/create", formData)
          .then((response) => {
            resolve(response);
            commit("ADD_DEPARTMENT", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allDepartments: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/department/all", data)
          .then((response) => {
            commit("FETCH_DEPARTEMENTS", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deleteDepartment: function (commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/department/delete/" + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateDepartment: function (commit, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post("/department/update/" + formData.get("id"), formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getDepartment({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/department/show/${id}`)
          .then((response) => {
            resolve(response);
            commit("GET_DEPARTMENT", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
