import axios from "../../libs/axios";

export default {
  namespaced: true,

  state: {
    materials: [],
    outputmaterials: [],
    outputtypes: [],
  },

  mutations: {
    ADD_OUTPUT(state, data) {
      state.outputmaterials.push(data);
    },

    FETCH_OUTPUT_MATERIALS(state, data) {
      state.outputmaterials = data;
    },

    FETCH_OUTPUT_TYPES(state, data) {
      state.outputtypes = data;
    },
  },

  actions: {

    getOutputTypes({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`outputs`)
          .then((response) => {
            resolve(response);
            commit("FETCH_OUTPUT_TYPES", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getOutputMaterials({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/output-reports`)
          .then((response) => {
            resolve(response);
            commit("FETCH_OUTPUT_MATERIALS", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    addOutputMaterial({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/output-report`, formData)
          .then((response) => {
            resolve(response);
            commit("ADD_OUTPUT", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    updateAccessory(commit, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post(`/type/accessory/${formData.get("id")}`, formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    deleteAccessory(commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/type/accessory/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    // getTypeAccessories({ commit }, type_id) {
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .get(`/type/accessories/${type_id}`)
    //       .then((response) => {
    //         resolve(response);
    //         commit("FETCH_TYPE_ACCESSORIES", response.data);
    //       })
    //       .catch((error) => reject(error));
    //   });
    // },
  },
};
