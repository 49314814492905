import axios from "../../libs/axios";

export default {
  namespaced: true,

  state: {
    countries: [],
    country: {},
  },

  getters: {},

  mutations: {
    ADD_COUNTRY(state, data) {
      state.countries.data.push(data);
    },

    FETCH_COUNTRIES(state, data) {
      state.countries = data;
    },
  },

  actions: {
    createCountry: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/country/create", formData)
          .then((response) => {
            resolve(response);
            commit("ADD_COUNTRY", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allCountries: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/country/all", data)
          .then((response) => {
            commit("FETCH_COUNTRIES", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
