import axios from "../../libs/axios";

export default {
  namespaced: true,

  state: {
    sectors: [],
    sector: {},
  },

  mutations: {
    FETCH_SECTORS(state, data) {
      state.sectors = data;
    },
  },

  actions: {
    fetchSectors({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("activity-sector/all")
          .then((response) => {
            resolve(response);
            commit("FETCH_SECTORS", response.data);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
