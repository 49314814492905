import Vue from 'vue';

import NProgress from 'nprogress';
import Router from 'vue-router';

import { routes } from './routes';

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes: routes,
});

router.beforeEach((to, from, next) => {
  const context = {
    to,
    from,
    next,
    router
  };
  NProgress.start()
  if (to.meta.middleware) {
    to.meta.middleware.forEach((middleware, index) => {
      if (index + 1 == to.meta.middleware.length) {
        //c'est le dernier middleware on peut faire next
        middleware(context);
      } else {
        middleware(context, false);
      }
    });
  }
  return next();
});

router.afterEach(() => {
  NProgress.done()
});

export default router;