import 'remixicon/fonts/remixicon.css';

import Vue from 'vue';

import Vuetify from 'vuetify/lib/framework';
import fr from 'vuetify/lib/locale/fr';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

Vue.component("my-component", {
  methods: {
    changeLocale() {
      this.$vuetify.lang.current = "fr";
    },
  },
});

export default new Vuetify({
  lang: {
    locales: {
      fr
    },
    current: "fr",
  },
  theme: {
    themes: {
      light: {
        primary: colors.teal.lighten1, // #E53935
        secondary: colors.red.lighten5, // #FFCDD2
        accent: colors.teal.base, // #3F51B5
      },
    },
  },
});