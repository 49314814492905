import axios from "../../libs/axios";

export default {
  namespaced: true,

  state: {
    stocks: [],
    accessories: [],
    materials: [],
    materials_in_stock: [],
    supplier_stocks: [],
    not_empty_stocks: [],
  },

  getters: {},

  mutations: {
    ADD_STOCK(state, data) {
      state.stocks.data.push(data);
    },

    FETCH_STOCKS(state, data) {
      state.stocks = data;
    },

    FETCH_MATERIALS_IN_STOCK(state, data) {
      state.materials_in_stock = data;
    },

    FETCH_NOT_EMPTY_STOCKS(state, data) {
      state.not_empty_stocks = data;
    },

    FETCH_ACCESSORIES(state, data) {
      state.accessories = data;
    },

    FETCH_MATERIALS(state, data) {
      state.materials = data;
    },

    FETCH_SUPPLIER_STOCKS(state, data) {
      state.supplier_stocks = data;
    },
  },

  actions: {
    getAllStocks: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/stock/all", data)
          .then((response) => {
            commit("FETCH_STOCKS", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    materialsInStock: function ({ commit }, ref) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/materials-in-stock?ref=${ref}`)
          .then((response) => {
            commit("FETCH_MATERIALS_IN_STOCK", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getAllAccessoriesIn: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/stock/accessories-in`, data)
          .then((response) => {
            resolve(response);
            commit("FETCH_ACCESSORIES", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    getAllMaterialsIn: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/stock/get-materials", data)
          .then((response) => {
            commit("FETCH_STOCKS", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    materialsInStockFilter(
      { commit },
      { search, min_quantity, max_quantity, type_id }
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get("/stock/materials-in-stock-filter", {
            params: {
              type_id: type_id,
              search: search,
              min_quantity: min_quantity,
              max_quantity: max_quantity,
            },
          })
          .then((response) => {
            resolve(response);
            commit("FETCH_STOCKS", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    accessoriesInStockFilter(
      { commit },
      { search, min_quantity, max_quantity, type_id }
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get("/stock/accessories-in-stock-filter", {
            params: {
              type_id: type_id,
              search: search,
              min_quantity: min_quantity,
              max_quantity: max_quantity,
            },
          })
          .then((response) => {
            resolve(response);
            commit("FETCH_ACCESSORIES", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    createStock({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/stock/create", formData)
          .then((response) => {
            resolve(response);
            commit("ADD_STOCK", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    subtractStock({ commit }, formData) {
      formData.append("_method", "put");
      return new Promise((resolve, reject) => {
        axios
          .post("/stock/subtract", formData)
          .then((response) => {
            commit("FETCH_STOCKS", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteStock(commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/stock/delete/" + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    exportPdf() {
      return new Promise((resolve, reject) => {
        axios
          .get("/stock/export/pdf")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    exportExcel() {
      return new Promise((resolve, reject) => {
        axios
          .get("/stock/export/excel")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateStock: function (commit, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post("/stock/update/" + formData.get("id"), formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    importStocksFromExcel(commit, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/stock/import`, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    showStock: function (commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/stock/show/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getStockMaterials: function ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/stock/${id}/materials`)
          .then((response) => {
            commit("FETCH_MATERIALS", response.data);
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },

    supplierStocks: function ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/supplier/${id}/stocks`)
          .then((response) => {
            commit("FETCH_SUPPLIER_STOCKS", response.data);
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },

    notEmptyStocks: function ({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`stock/all-not-empty`)
          .then((response) => {
            resolve(response);
            commit("FETCH_NOT_EMPTY_STOCKS", response.data);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
