import axios from "../../libs/axios";

export default {
  namespaced: true,

  state: {
    agents: [],
    userInfo: JSON.stringify(localStorage.getItem("userInfo")),
  },

  getters: {},

  mutations: {
    ADD_AGENT(state, data) {
      state.agents.data.push(data);
    },

    FETCH_AGENTS(state, data) {
      state.agents = data;
    },

    UPDATE_AGENT_INFO(state, data) {
      localStorage.setItem("userInfo", JSON.stringify(data));
      state.userInfo = data;
    },
  },

  actions: {
    createAgent: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/user/add-agent", formData)
          .then((response) => {
            resolve(response);
            commit("ADD_AGENT", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allAgents: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/user/all-agents", data)
          .then((response) => {
            commit("FETCH_AGENTS", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    allSiteAgents: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/user/all-agents", data)
          .then((response) => {
            commit("FETCH_AGENTS", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    agentFilter({ commit }, { searchD, rank }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/research?search=${searchD}&rank=${rank}`)
          .then((response) => {
            resolve(response);
            commit("FETCH_AGENTS", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    deleteAgent(commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/user/delete/" + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateAgent({ commit }, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post(`/user/update/${formData.get("id")}`, formData)
          .then((response) => {
            resolve(response);
            commit("UPDATE_AGENT_INFO", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    changePassword(commit, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/user/change-password`, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateMyAgent(commit, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post(`/user/update/${formData.get("id")}`, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    showAgent(commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/show/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
