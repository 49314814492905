export default {
    namespaced: true,

    state: {
      barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    
      drawer: null,
    },

    getters: {
        drawer: state => state.drawer,
    },

    mutations: {
      SET_BAR_IMAGE (state, payload) {
        state.barImage = payload
      },
      
      SET_DRAWER (state, payload) {
        state.drawer = payload
      },
    },

    actions: {
        setDrawer({commit},payload) {
            commit('SET_DRAWER', payload)
        }
    },
  }