import axios from "./../../libs/axios";

export default {
  namespaced: true,

  state: {
    accessories: [],
    typeAccessories: [],
    accessory: {},
  },

  mutations: {
    ADD_ACCESSORY(state, data) {
      state.accessories.push(data);
    },

    FETCH_ACCESSORIES(state, data) {
      state.accessories = data;
    },

    FETCH_TYPE_ACCESSORIES(state, data) {
      state.typeAccessories = data;
    },
  },

  actions: {
    getAllAccessories({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/type/accessories/${id}`)
          .then((response) => {
            resolve(response);
            commit("FETCH_ACCESSORIES", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    addAccessory({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/type/accessory`, formData)
          .then((response) => {
            resolve(response);
            commit("ADD_ACCESSORY", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    updateAccessory(commit, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post(`/type/accessory/${formData.get("id")}`, formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    deleteAccessory(commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/type/accessory/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    getTypeAccessories({ commit }, type_id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/type/accessories/${type_id}`)
          .then((response) => {
            resolve(response);
            commit("FETCH_TYPE_ACCESSORIES", response.data);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
