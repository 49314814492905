import { auth, guest } from "@/middleware";

export const routes = [
  {
    path: "/auth/login",
    name: "Connexion",
    component: () => import("@/views/auth/Login.vue"),
    meta: {
      middleware: [guest],
    },
  },
  {
    path: "/auth/register",
    name: "Inscription",
    component: () => import("@/views/auth/Register.vue"),
  },
  {
    path: "/auth/forgetPassword",
    name: "Mode de passe oublié",
    component: () => import("@/views/auth/ForgetPassword.vue"),
  },
  {
    path: "/auth/changePassword/:email/:token",
    name: "Mode de passe oublié",
    component: () => import("@/views/auth/ChangePassword.vue"),
  },
  {
    path: "/",
    component: () => import("@/views/Index.vue"),
    meta: {
      middleware: [auth],
    },
    children: [
      {
        path: "",
        name: "Tableau de bord",
        component: () => import("@/views/pages/Dashboard/Dashboard.vue"),
        meta: {
          middleware: [auth],
        },
      },
      {
        path: "/users",
        name: "Responsable",
        component: () => import("@/views/pages/User/Users.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Liste des responsables", link: "/users" },
          ],
        },
      },

      {
        path: "/show-user/:id",
        name: "Informations du responsable",
        component: () => import("@/views/pages/User/ShowUser.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Liste des responsables", link: "/users" },
            { text: "Informations du responsable", link: "/show-user/" },
          ],
        },
      },

      {
        path: "/import-user",
        name: "Importation en masse de responsables",
        component: () => import("@/views/pages/User/ImportUser.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Import de masse de responsables", link: "/import-user" },
          ],
        },
      },

      {
        path: "/collaborator",
        name: "Collaborateurs",
        component: () => import("@/views/pages/Collaborators/Collaborator.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            {
              text: "Liste des Collaborateurs",
              link: "/collaborator",
              params: { id: "id" },
            },
          ],
        },
      },
      {
        path: "/show-collaborator/:id",
        name: "Informations de l'agent",
        component: () =>
          import("@/views/pages/Collaborators/ShowCollaborator.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Liste des Collaborateurs", link: "/collaborator" },
            {
              text: "Informations de l'agent",
              link: "/show-collaborator/",
            },
          ],
        },
      },

      {
        path: "/account",
        name: "Compte",
        component: () => import("@/views/pages/Account.vue"),
      },
      {
        path: "/materials",
        name: "Liste des immobilisations",
        component: () => import("@/views/pages/Material/Materials.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Liste des immobilisations", link: "/materials" },
          ],
        },
      },

      {
        path: "/show-material/:id",
        name: "Détails de l'immobilisation",
        component: () => import("@/views/pages/Material/ShowMaterial.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Liste des immobilisations", link: "/materials" },
            { text: "Détails de l'immobilisation", link: "/show-material/" },
          ],
        },
      },

      {
        path: "/state-material",
        name: "Etats des immobilisations",
        component: () => import("@/views/pages/Material/StateMaterial.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Liste des états des immobilisations", link: "/state-material" },
          ],
        },
      },

      // {
      //   path: "/out-materials",
      //   name: "Sorties",
      //   component: () => import("@/views/pages/OutMaterials/OutMaterials.vue"),
      //   meta: {
      //     breadcrumb: [
      //       { text: "Dashboard", link: "/" },
      //       { text: "Liste des immobilisations sorties", link: "/out-materials" },
      //     ],
      //   },
      // },

      {
        path: "/out-materials",
        name: "Immobilisations Sorties",
        component: () => import("@/views/pages/OutMaterials/ShowOutMaterials/ShowOutMaterials.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Liste des immobilisations sorties", link: "/out-materials" },
          ],
        },
      },

      {
        path: "/enterprise-info",
        name: "Détails de l'entreprise",
        component: () => import("@/views/Enterprise.vue"),
      },

      {
        path: "/show-state-materials/:id",
        name: "Liste des immobilisations d'un état", // état à rendre dynamique
        component: () =>
          import("@/views/pages/Material/ShowStateMaterials.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Liste des états des immobilisations", link: "/state-material" },
            {
              text: "Liste des immobilisations d'un état",
              link: "/show-state-materials",
            },
          ],
        },
      },

      {
        path: "/material-movement",
        name: "Mouvements d'immobilisations",
        component: () => import("@/views/pages/Movements/Movements.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            {
              text: "Mouvements d'immobilisations",
              link: "/material-movement",
            },
          ],
        },
      },

      {
        path: "/show-movement/:id",
        name: "Détails du mouvement",
        component: () => import("@/views/pages/Movements/ShowMovements.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Mouvements d'immobilisations", link: "/material-movement" },
            { text: "Détails du mouvement", link: "/show-movement/" },
          ],
        },
      },

      {
        path: "/material-tracking-file",
        name: "Fiche de suivi d'immobilisation",
        component: () => import("@/views/pages/MaterialTracking.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            {
              text: "Entreprise",
              link: "/enterprise-info",
            },
          ],
        },
      },

      {
        path: "/material-assignments",
        name: "Affectations d'immobilisations",
        component: () => import("@/views/pages/Stocks/Assignments.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            {
              text: "Affectations d'immobilisations",
              link: "/material-assignments",
            },
          ],
        },
      },

      {
        path: "/stock/new",
        name: "Affectations d'immobilisations",
        component: () => import("@/views/pages/Stocks/AddStock/New.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            {
              text: "Magasin",
              link: "/stocks",
            },
          ],
        },
      },

      {
        path: "/show-assignments",
        name: "Détails d'affectations de l'immobilisation",
        component: () => import("@/views/pages/Stocks/ShowAssignments.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            {
              text: "Affectations d'immobilisations",
              link: "/material-assignments",
            },
            {
              text: "Détails d'affectations",
              link: "/show-assignments",
            },
          ],
        },
      },

      {
        path: "/type-material",
        name: "Types d'immobilisation",
        component: () => import("@/views/pages/Material/TypeMaterial.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            {
              text: "Liste des types",
              link: "/type-material",
            },
          ],
        },
      },

      {
        path: "/show-type-materials/:id",
        name: "Immobilisations par type", // rendre dynamique le type
        component: () => import("@/views/pages/Material/ShowTypeMaterials.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            {
              text: "Liste des types",
              link: "/type-material",
            },
            {
              text: "Liste des immobilisations par type",
              link: "/show-type-materials",
            },
          ],
        },
      },
      {
        path: "/show-type-accessory/:id",
        name: "Details accessoires", // rendre dynamique le type
        component: () => import("@/views/pages/Material/ShowTypeAccessory.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            {
              text: "Liste des types",
              link: "/type-material",
            },
            {
              text: "Détails de l'accessoire",
              link: "/show-type-accessory",
            },
          ],
        },
      },

      {
        path: "/show-movement/:id",
        name: "Détails du mouvement ",
        component: () => import("@/views/pages/Movements/ShowMovements.vue"),
      },

      {
        path: "/site",
        name: "Liste des sites de l'entreprise ",
        component: () => import("@/views/pages/Site/Site.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Liste des sites", link: "/site" },
          ],
        },
      },

      {
        path: "/show-site/:id",
        name: "Informations du site",
        component: () => import("@/views/pages/Site/ShowSite.vue"),
      },

      {
        path: "/stocks",
        name: "Liste des stocks",
        component: () => import("@/views/pages/Stocks/Stocks.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Stocks", link: "/stocks" },
          ],
        },
      },

      {
        path: "/stocks/:id/materials",
        name: "stock-materials",
        component: () => import("@/views/pages/Stocks/StockMaterials.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Stocks", link: "/stocks" },
            // { text: "Matérials du stock", link: "/stocks/#" },
          ],
        },
      },

      {
        path: "/suppliers",
        name: "Liste des fournisseurs",
        component: () => import("@/views/pages/Supplier/Suppliers.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Fournisseurs", link: "/suppliers" },
          ],
        },
      },

      {
        path: "/stock-supplies",
        name: "Approvisionnements",
        component: () => import("@/views/pages/Stocks/Supplies.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Liste des approvisionnements", link: "/stock-supplies" },
          ],
        },
      },

      {
        path: "/material-transfers",
        name: "transfers",
        component: () => import("@/views/pages/Stocks/Transfers/Index.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            {
              text: "Transferts d'immobilisations",
              link: "/material-transfers",
            },
          ],
        },
      },

      {
        path: "/transfer/:ref/materials",
        name: "/transfer-materials",
        component: () => import("@/views/pages/Stocks/Transfers/Materials.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            {
              text: "Transferts d'immobilisations",
              link: "/material-transfers",
            },
            {
              text: "Immobilisations transférées",
              link: "/transfer/:ref/materials",
            },
          ],
        },
      },

      {
        path: "/stock-supply/site/:id",
        name: "pending-supply",
        component: () => import("@/views/pages/Stocks/SupplyInSite.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Liste des approvisionnements", link: "/stock-supplies" },
            {
              text: "Liste des immobilisations approvisionnés",
              link: "/stock-supply/site",
            },
          ],
        },
      },

      {
        path: "/import-material",
        name: "Importation en masse d'immobilisations",
        component: () => import("@/views/pages/Material/ImportMaterial.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Import de masse d'immobilisations ", link: "/import-material" },
          ],
        },
      },

      {
        path: "/import-stock",
        name: "Importation en masse de stocks",
        component: () => import("@/views/pages/Stocks/ImportStock.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Import de masse d'immobilisations ", link: "/import-stock" },
          ],
        },
      },

      {
        path: "/inventories",
        name: "Liste des Inventaires",
        component: () => import("@/views/pages/Inventory/Inventories.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Liste des Inventaires", link: "/inventories" },
          ],
        },
      },

      {
        path: "/add-inventory",
        name: "Nouvel inventaire",
        component: () => import("@/views/pages/Inventory/AddInventory.vue"),
      },

      {
        path: "/inventory/items",
        name: "Immobilisations inventoriées",
        component: () => import("@/views/pages/Inventory/InventoryItems.vue"),
      },

      {
        path: "/inventory/deskrooms/:id",
        name: "Bureaux inventoriés",
        component: () =>
          import("@/views/pages/Inventory/InventoryInDeskrooms.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Liste des Inventaires", link: "/inventories" },
            {
              text: "Liste des immobilisations ou stocks inventoriés",
              link: "/inventory/deskrooms/",
            },
          ],
        },
      },

      {
        path: "/show-departements",
        name: "Liste des départements",
        component: () => import("@/views/pages/Departments/Departments.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Liste des departements", link: "/show-departements" },
          ],
          children: [
            {
              path: "/show-deskrooms/:id",
              name: "Liste des bureaux",
              component: () =>
                import("@/views/pages/Departments/Deskrooms.vue"),
              meta: {
                breadcrumb: [
                  { text: "Dashboard", link: "/" },
                  {
                    text: "Liste des departements",
                    link: "/show-departements",
                  },
                  { text: "Liste des bureaux", link: "/show-deskrooms/:id" },
                ],
              },
            },
          ],
        },
      },

      {
        path: "/show-deskrooms/:id",
        name: "Liste des bureaux",
        component: () => import("@/views/pages/Departments/Deskrooms.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Liste des departements", link: "/show-departements" },
            {
              text: "Liste des bureaux",
              link: "/show-deskrooms/",
            },
          ],
        },
      },

      {
        path: "/show-deskroom-user/:id",
        name: "Liste des responsables par bureaux",
        component: () => import("@/views/pages/Departments/UserDeskrooms.vue"),
        meta: {
          breadcrumb: [
            { text: "Dashboard", link: "/" },
            { text: "Liste des departements", link: "/show-departements" },
            {
              text: "Liste des bureaux",
              link: (route) => `/show-deskrooms/${route.params.id}`,
            },
            {
              text: "Liste des responsables par bureaux",
              link: (route) => `/show-deskrooms-user/${route.params.id}`,
            },
          ],
        },
        // beforeEnter: (to, from, next) => {
        //   to.meta.breadcrumb[3].link = `/show-deskrooms-user/${to.params.id}`
        //   next();
        // }
      },

      {
        path: "/faq",
        name: "FAQ",
        component: () => import("@/views/pages/Faq.vue"),
      },

      {
        path: "/guide",
        name: "Guide",
        component: () => import("@/views/pages/Guide.vue"),
      },
    ],
  },
];
