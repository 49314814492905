import axios from "../../libs/axios";

export default {
  namespaced: true,

  state: {
    suppliers: [],
  },

  getters: {},

  mutations: {
    ADD_SUPPLIER(state, data) {
      state.suppliers.data.push(data);
    },

    FETCH_SUPPLIERS(state, data) {
      state.suppliers = data;
    },
  },

  actions: {
    allSuppliers({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/suppliers", data)
          .then((response) => {
            commit("FETCH_SUPPLIERS", response.data.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    searchSupplier({ commit }, search) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/search/supplier?query=${search}`)
          .then((response) => {
            resolve(response);
            commit("FETCH_SUPPLIERS", response.data.data);
          })
          .catch((error) => reject(error));
      });
    },

    createSupplier({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/supplier", formData)
          .then((response) => {
            resolve(response);
            commit("ADD_SUPPLIER", response.data.data);
          })
          .catch((error) => reject(error));
      });
    },

    updateSupplier(commit, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post(`/supplier/${formData.get("id")}`, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteSupplier(commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/supplier/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
