import axios from "../../libs/axios";

export default {
  namespaced: true,

  state: {
    deskrooms: [],
    deskroom: {},
  },

  getters: {},

  mutations: {
    ADD_DESKROOM(state, data) {
      state.deskrooms.data.push(data);
    },

    FETCH_DESKROOMS(state, data) {
      state.deskrooms = data;
    },

    GET_DESKROOM(state, data) {
      state.deskroom = data;
    },
  },

  actions: {
    createDeskroom: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/deskroom/create", formData)
          .then((response) => {
            resolve(response);
            commit("ADD_DESKROOM", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allDeskrooms: function ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/deskroom/all/" + id)
          .then((response) => {
            commit("FETCH_DESKROOMS", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getDeskroom({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`deskroom/show/${id}`)
          .then((response) => {
            resolve(response);
            commit("GET_DESKROOM", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateDeskroom(commit, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post("/deskroom/update/" + formData.get("id"), formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteDeskroom(commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/deskroom/delete/" + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
