<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "App",

  computed: {
    ...mapState("site", ["overlay"]),
  },

  mounted() {
    this.overlay;
  },

};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Poppins");

.v-application {
  font-family: "Poppins" !important;
}

/* custom nprogress color */

#nprogress .bar {
  background: #011f07;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}
#nprogress .bar {
  background: #011f07;

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 4px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 0px transparent;
  border-top-color: #011f07;
  border-left-color: #011f07;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}
/* end */

.title-description {
  letter-spacing: 0px;
  line-height: 0px;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}
</style>
