import axios from "./../../libs/axios";

export default {
    namespaced: true,

    state: {
        mat_characteristics: [],
    },

    getters:{},

    mutations: {
        FETCH_CHARACTERISTICS(state, data) {
            state.mat_characteristics = data;
        },

        ADD_CHARACTERISTIC(state, data) {
            state.mat_characteristics.push(data);
        },
    },
    
    actions: {
        allCharacteristics({ commit }, data) {
            return new Promise((resolve, reject) => {
              axios
                .get(`/mat-characteristic/all`, data)
                .then((response) => {
                  commit("FETCH_CHARACTERISTICS", response.data);
                  resolve(response);
                })
                .catch((error) => reject(error));
            });
          },
      
          addCharacteristic({ commit }, formData) {
            return new Promise((resolve, reject) => {
              axios
                .post(`/mat-characteristic/create`, formData)
                .then((response) => {
                  resolve(response);
                  commit("ADD_CHARACTERISTIC", response.data);
                })
                .catch((error) => reject(error));
            });
          },
      
          updateCharacteristic(commit, formData) {
            formData.append("_method", "PUT");
            return new Promise((resolve, reject) => {
              axios
                .post(`/mat-characteristic/update/${formData.get("id")}`, formData)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
            });
          },
      
          deleteCharacteristic(commit, id) {
            return new Promise((resolve, reject) => {
              axios
                .delete(`/mat-characteristic/delete/${id}`)
                .then((response) => resolve(response))
                .catch((error) => reject(error));
            });
          },
    }
}