import axios from "../../libs/axios";

export default {
  namespaced: true,

  state: {
    inventories: [],
    materials_associated: [],
    inventory_materials: [],
    inventory_stocks: [],
    stocks_not_in_inventory: [],
    stockInventoryMaterials: [],
    pending_inventory: null,
    pending_stock_inventory: null,
    isStockInventoryInProgress: false,
    isInventoryInProgress: false,
  },

  getters: {
    getCurrentSite: (state) => state.current_site,
  },

  mutations: {
    CURRENT_SITE(state, data) {
      state.current_site = data;
    },

    CREATE_INVENTORY(state, data) {
      if(data.inventory_type == 0) state.pending_inventory = data;
      if(data.inventory_type == 1) state.pending_stock_inventory = data;
    },

    FETCH_INVENTORIES(state, data) {
      state.inventories = data;
    },

    ADD_MATERIAL_ASSOCIATED(state, data) {
      state.materials_associated.data.push(data);
    },

    FETCH_MATERIALS_ASSOCIATED(state, data) {
      state.materials_associated = data;
    },

    ADD_INVENTORY_MATERIAL(state, data) {
      state.inventory_materials.data.push(data);
    },

    FETCH_INVENTORY_MATERIALS(state, data) {
      state.inventory_materials = data;
    },

    FETCH_INVENTORY_STOCKS(state, data) {
      state.inventory_stocks = data;
    },

    FETCH_STOCKS_NOT_IN_INVENTORY(state, data) {
      state.stocks_not_in_inventory = data;
    },

    FETCH_STOCKS_INVENTORY_MATERIALS(state, data) {
      state.stockInventoryMaterials = data;
    },

    UPDATE_STOCK_INVENTORY_PROGRESS_STATUS(state, data) {
      state.isStockInventoryInProgress = data;
    },

    UPDATE_INVENTORY_PROGRESS_STATUS(state, data) {
      state.isInventoryInProgress = data;
    },
  },

  actions: {
    createInventory: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/inventory/create", formData)
          .then((response) => {
            commit("CREATE_INVENTORY", response.data);
            if(response.data.inventory_type == 0)
              commit("UPDATE_INVENTORY_PROGRESS_STATUS", true);
            else commit("UPDATE_STOCK_INVENTORY_PROGRESS_STATUS", true);

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    pendingInventory: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .get("/inventory/pending", formData)
          .then((response) => {
            commit("CREATE_INVENTORY", response.data);
            commit("UPDATE_INVENTORY_PROGRESS_STATUS", response.data !== null ? true: false);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    
    pendingStockInventory: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .get("/inventory/stock/pending", formData)
          .then((response) => {
            commit("CREATE_INVENTORY", response.data);
            commit("UPDATE_STOCK_INVENTORY_PROGRESS_STATUS", response.data !== null ? true: false);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allInventories: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/inventory/all", data)
          .then((response) => {
            commit("FETCH_INVENTORIES", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    showInventory(commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/inventory/show/" + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    associateMaterialToInventory: function (commit, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/inventory/" + formData.get("id") + "/associate", formData)
          .then((response) => {
            // commit("ADD_MATERIAL_ASSOCIATED", response.data.materials);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    associateStockToInventory: function (commit, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/inventory/" + formData.get("id") + "/associate-stock", formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    detachStockFromInventory: function (commit, {id, stockId}) {
      return new Promise((resolve, reject) => {
        axios
          .get("/inventory/" + id + "/detach-stock/" + stockId)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    
    stocksNotInInventory: function ({commit}, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/inventory/" + id + "/stocks-not-in")
          .then((response) => {
            commit('FETCH_STOCKS_NOT_IN_INVENTORY', response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    closeStockInventory: function ({commit}, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/inventory/stock/" + id + "/close")
          .then((response) => {
            commit("UPDATE_STOCK_INVENTORY_PROGRESS_STATUS", false);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    // removeMaterialFromInventory: function (commit, formData, id) {
    //   formData.append("_method", "PUT");
    //   return new Promise((resolve, reject) => {
    //     axios
    //       .post(`/inventory/${formData.get("id")}/update/${id}`)
    //       .then((response) => {
    //         resolve(response);
    //       })
    //       .catch((error) => reject(error));
    //   });
    // },

    inventoryMaterials: function ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/inventory/" + id + "/materials")
          .then((response) => {
            commit("FETCH_INVENTORY_MATERIALS", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    inventoryStocks: function ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/inventory/" + id + "/stocks")
          .then((response) => {
            commit("FETCH_INVENTORY_STOCKS", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    closeInventory: function ({ commit }, id, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/inventory/" + id + "/close", data)
          .then((response) => {
            commit("UPDATE_INVENTORY_PROGRESS_STATUS", false);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deleteInventory: function ({ commit }, {id, type}) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/inventory/" + id + "/delete")
          .then((response) => {
            if (type == 0) 
              commit("UPDATE_INVENTORY_PROGRESS_STATUS", false);
            else commit("UPDATE_STOCK_INVENTORY_PROGRESS_STATUS", false);

            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateInventory: function (commit, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post(`/inventory/${formData.get("id")}/update`, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    exportExcel: function (commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/inventory/export/" + id + "/excel")

          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    exportPdf: function (commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/inventory/export/" + id + "/pdf")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    exportStockInventoryPdf: function (commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/inventory/stock/export/" + id + "/pdf")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    
    fetchStockInventoryMaterials: function ({ commit }, stockInventoryId) {
      return new Promise((resolve, reject) => {
        axios
          .get(`inventory-stock/${stockInventoryId}/materials`)
          .then((response) => {
            commit("FETCH_STOCKS_INVENTORY_MATERIALS", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
