import axios from "./../../libs/axios";

export default {
  namespaced: true,
  state: {
    mat_convenants: [],
    convenant: {},
  },

  getters: {},

  mutations: {
    FETCH_CONVENANTS(state, data) {
      state.mat_convenants = data;
    },

    ADD_CONVENANT(state, data) {
      state.mat_convenants.push(data);
    },
  },

  actions: {
    allConvenants({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/mat-convenant/all`, data)
          .then((response) => {
            commit("FETCH_CONVENANTS", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    addConvenant({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/mat-convenant/create`, formData)
          .then((response) => {
            resolve(response);
            commit("ADD_CONVENANT", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    updateConvenant(commit, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post(`/mat-convenant/update/${formData.get("id")}`, formData)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    deleteConvenant(commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/mat-convenant/delete/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
