// axios
import axios from "axios";

import store from "@/store/index";

const baseURL = {
  locale: "http://127.0.0.1:8000",
  remote: "http://192.168.100.104:8000",
  live: "https://api-sirius.ky-solutions.fr",
};
const axiosIns = axios.create({
  baseURL: baseURL.live,
});

axiosIns.interceptors.request.use((config) => {
  config.headers = {
    Authorization: "Bearer " + store.state.auth.token,
    "Access-Control-Allow-Origin": "*",
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
  };
  return config;
});
axiosIns.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      localStorage.clear();
      location.replace("/auth/login");
    }
    return Promise.reject(error);
  }
);

// Vue.prototype.$http = axiosIns;

export default axiosIns;
