import axios from "../../libs/axios";

export default {
  namespaced: true,

  state: {
    sites: [],
    sites_except_active: [],
    site_deskrooms: [],
    site: {},
    overlay: false,
    siteInfo: JSON.parse(localStorage.getItem("siteInfo")),
  },

  getters: {
    setOverlay: (state) => state.overlay,
  },

  mutations: {
    ADD_SITE(state, data) {
      state.sites.data.push(data);
    },

    FETCH_SITES(state, data) {
      state.sites = data;
    },

    FETCH_SITES_EXCEPT_CURRENT(state, data) {
      state.sites_except_active = data;
    },

    FETCH_SITE_DESKROOMS(state, data) {
      state.site_deskrooms = data;
    },

    UPDATE_SITE_INFO(state, data) {
      localStorage.setItem("siteInfo", JSON.stringify(data));
      state.siteInfo = data;
    },

    SET_OVERLAY(state) {
      state.overlay = true;
      setTimeout(() => {
        state.overlay = false;
      }, 1000);
    },
  },

  actions: {
    createSite: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/site/create", formData)
          .then((response) => {
            commit("UPDATE_SITE_INFO", response.data);
            resolve(response);
            commit("ADD_SITE", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    activeSite: function ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get("/user/updateSite/" + id)
          .then((response) => {
            commit("UPDATE_SITE_INFO", response.data.site);
            commit("SET_OVERLAY");
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allSites: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/site/all", data)
          .then((response) => {
            commit("FETCH_SITES", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allSitesExceptActive: function ({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/site/all-except-current")
          .then((response) => {
            commit("FETCH_SITES_EXCEPT_CURRENT", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allDeskroomsSite: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/site/alldesk", data)
          .then((response) => {
            commit("FETCH_SITE_DESKROOMS", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateSite: ({ commit }, formData) => {
      formData.append("_method", "put");
      new Promise((resolve, reject) => {
        axios
          .post("/site/update/" + formData.get("id"), formData)
          .then((response) => {
            commit("UPDATE_SITE_INFO", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deleteSite: function (commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/site/delete/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
