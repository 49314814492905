function auth({ next, router }, canGoNext = true) {
  var token = localStorage.getItem("token");

  if (token == null) {
    return router.replace("/auth/login");
  }
  if (canGoNext) {
    return next();
  }
}


function guest({ next, router }, canGoNext = true) {
  var token = localStorage.getItem("token")
  if (token != null) return router.replace('/')
  if (canGoNext) return next()
}



export { auth, guest };
