import axios from "../../libs/axios";

export default {
  namespaced: true,

  state: {
    supplies: [],
    supply: {},
    associated_stocks: [],
    supply_stocks: [],
    pending_supply: {},
  },

  mutations: {
    FETCH_SUPPLIES(state, data) {
      state.supplies = data;
    },

    CREATE_SUPPLY(state, data) {
      state.pending_supply = data;
    },

    SHOW_SUPPLY(state, data) {
      state.supply = data;
    },

    FETCH_ASSOCIATED_STOCKS(state, data) {
      state.associated_stocks = data;
    },

    ADD_STOCK_TO_SUPPLY(state, data) {
      state.supply_stocks.data.push(data);
    },
  },

  actions: {
    fetchSupplies({ commit }, searchTxt = null) {
      return new Promise((resolve, reject) => {
        !searchTxt ? axios.get(`supply/all`).then((response) => {
          resolve(response);
          commit("FETCH_SUPPLIES", response.data);
        }).catch((error) => {
          reject(error);
        }) :
          axios.post(`supply/search`, { search: searchTxt }).then((response) => {
            resolve(response);
            commit("FETCH_SUPPLIES", response.data);
          }).catch((error) => {
            reject(error);
          });
      });
    },

    newSupply({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`supply/create`, formData)
          .then((response) => {
            resolve(response);
            commit("CREATE_SUPPLY", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    associateStockToSupply({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`supply/attach`, formData)
          .then((response) => {
            resolve(response);
            commit("ADD_STOCK_TO_SUPPLY");
          })
          .catch((error) => reject(error));
      });
    },

    getSupplyStocks({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/supply/${id}/stocks`)
          .then((response) => {
            resolve(response);
            commit("FETCH_ASSOCIATED_STOCKS", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    closeSupply({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/supply/close`)
          .then((response) => {
            resolve(response);
            commit("CREATE_SUPPLY", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    cancelSupply({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/supply/${id}/cancel`)
          .then((response) => {
            resolve(response);
            commit("CREATE_SUPPLY", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    detachStockFromSupply({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/supply/${formData.get("supply_id")}/detach/${formData.get("id")}`
          )
          .then((response) => {
            resolve(response);
            commit("CREATE_SUPPLY", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getSupplyById({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/supply/${id}/show`)
          .then((response) => {
            resolve(response);
            commit("SHOW_SUPPLY", response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    exportExcel: function (commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/supply/export/${id}/excel`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    exportPdf: function (commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/supply/export/${id}/pdf`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    searchIntoSupplyStocks: function ({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `supply/${formData.get(
              "supply_id"
            )}/stocks/search?query=${formData.get("search")}`
          )
          .then((response) => {
            resolve(response);
            commit("FETCH_ASSOCIATED_STOCKS", response.data);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
