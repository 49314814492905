import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import f from '@/plugins/objectToFormData'
import './plugins/base'
import '@/plugins/apexcharts'
import './registerServiceWorker'

Vue.config.productionTip = true
Vue.prototype.$formData = f

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
