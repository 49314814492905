import axios from '../../libs/axios';

export default {
  namespaced: true,

  state: {
    types: [],
    typesSeriesMaterials: [],
    type: {},
  },

  getters: {},

  mutations: {
    ADD_TYPE(state, data) {
      state.types.data.push(data);
    },

    FETCH_TYPES(state, data) {
      state.types = data;
    },

    GET_TYPE(state, data) {
      state.type = data;
    },

    FETCH_SERIES_MATERIALS(state, data) {
      state.typesSeriesMaterials = data;
    },
  },

  actions: {
    getAllTypes: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/type/all", data)
          .then((response) => {
            commit("FETCH_TYPES", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    createType({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/type/create", formData)
          .then((response) => {
            resolve(response);
            commit("ADD_TYPE", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    deleteType(commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/type/delete/" + id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getType({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/type/show/${id}`)
          .then((response) => {
            resolve(response);
            commit("GET_TYPE", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    updateType: function (commit, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post("/type/update/" + formData.get("id"), formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    typeStatisticMaterial({
      commit
    }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/type/statistic/materials')
          .then((response) => {
            commit('FETCH_SERIES_MATERIALS', response.data)
            resolve(response.data)
          })
          .catch((error) => reject(error));
      });
    },
  },
};
