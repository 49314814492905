import axios from "./../../libs/axios";

export default {
  namespaced: true,

  state: {
    transfers: [],
    transfer_materials: [],
    current_transfer: {},
  },

  mutations: {
    FETCH_TRANSFERS(state, data) {
      state.transfers = data;
    },

    CURRENT_TRANSFER(state, data) {
      state.current_transfer = data;
    },

    NEW_TRANSFER(state, data) {
      state.transfers.push(data);
    },

    ADD_TRANSFER_MATERIALS(state, data) {
      state.transfer_materials.push(data);
    },

    FETCH_TRANSFER_MATERIALS(state, data) {
      state.transfer_materials = data;
    },
  },

  actions: {
    fetchTransfers({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`transfers`)
          .then((response) => {
            resolve(response);
            commit("FETCH_TRANSFERS", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    startTransfer({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`transfer/new`, formData)
          .then((response) => {
            resolve(response);
            commit("NEW_TRANSFER", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    getTransferById({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`transfer/${id}`)
          .then((response) => {
            resolve(response);
            commit("CURRENT_TRANSFER", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    cancelTransfer({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`transfer/${id}/cancel`)
          .then((response) => {
            resolve(response);
            commit("CURRENT_TRANSFER", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    sendMaterials({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`transfer/${formData.get("transfer_id")}/proceed`, formData)
          .then((response) => {
            resolve(response);
            commit("ADD_TRANSFER_MATERIALS", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    confirmReception({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post(`transfer/${formData.get("transfer_id")}/confirm`, formData)
          .then((response) => {
            resolve(response);
            commit("ADD_TRANSFER_MATERIALS", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    getTransferMaterials({ commit }, transfer_reference) {
      return new Promise((resolve, reject) => {
        axios
          .get(`transfer/${transfer_reference}/materials`)
          .then((response) => {
            resolve(response);
            commit("FETCH_TRANSFER_MATERIALS", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    getIncomingTransfers({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`transfers/incoming`)
          .then((response) => {
            resolve(response);
            commit("FETCH_TRANSFERS", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    getOutcomingTransfers({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`transfers/outcoming`)
          .then((response) => {
            resolve(response);
            commit("FETCH_TRANSFERS", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    // Export transfert to pdf
    exportTransfersToPdf() {
      return new Promise((resolve, reject) => {
        axios
          .get(`/transfers/export/pdf`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    // Export transfert to excel
    exportTransfersToExcel() {
      return new Promise((resolve, reject) => {
        axios
          .get(`/transfers/export/excel`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
