import axios from "./../../libs/axios";

export default {
  namespaced: true,

  state: {
    materials: [],
    assignments: [],
    employees_materials: [],
    repairs_materials: [],
    assigned_materials: [],
    last_material_user: {},
    employees_materials_with_old: [],
    accessories: [],
    material_accessories: [],
    material_history: [],
    amortization_plan: [],

    updateAssignmentsTable: null,
  },

  getters: {
    updateAssignmentsTable: state => state.updateAssignmentsTable
  },

  mutations: {

    SET_ASSIGN_DATA_UPDATING(state, data) {
      state.updateAssignmentsTable = data;
    },

    ADD_EMPLOYEES_MATERIALS(state, data) {
      state.employees_materials.push(data);
    },

    ADD_MATERIAL(state, data) {
      state.materials.data.push(data);
    },

    FETCH_MATERIALS(state, data) {
      state.materials = data;
    },

    FETCH_ACCESSORIES(state, data) {
      state.accessories = data;
    },

    FETCH_ASSIGNMENTS(state, data) {
      state.assignments = data;
    },

    FETCH_REPAIRS_MATERIALS(state, data) {
      state.repairs_materials = data;
    },

    FETCH_ASSIGNED_MATERIALS(state, data) {
      state.assigned_materials = data;
    },

    FETCH_EMPLOYEES_MATERIALS(state, data) {
      state.employees_materials = data;
    },

    FETCH_MATERIALS_EMPLOYEES_WITH_OLD_EMPLOYEE(state, data) {
      state.employees_materials_with_old = data;
    },

    GET_MATERIAL_HISTORY(state, data) {
      state.material_history = data;
    },

    GET_LAST_MATERIAL_USER(state, data) {
      state.last_material_user = data;
    },

    FETCH_MATERIAL_ACCESSORIES(state, data) {
      state.material_accessories = data;
    },

    FETCH_MATERIAL_AMORTIZATION_PLAN(state, data) {
      state.amortization_plan = data.data;
    },

    ADD_EMPLOYEES_MATERIAL_ACCESSORIES(state, data) {
      state.material_accessories.push(data);
    },
  },

  actions: {
    allMaterials({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/material/all", data)
          .then((response) => {
            commit("FETCH_MATERIALS", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    allRepairMaterials({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/material/allbyrepair", data)
          .then((response) => {
            commit("FETCH_REPAIRS_MATERIALS", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    allAssignedMaterials({ commit }, { type_id, state_id, search }) {
      const data = {
        search: search,
        type_id: type_id,
        state_id: state_id
      };

      return new Promise((resolve, reject) => {
        axios.get("/material/assigned", { params: data })
          .then((response) => {
            commit("FETCH_ASSIGNED_MATERIALS", response.data.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    allAssignedMaterialsNotIn(
      { commit },
      { id, departmentId, deskroomId },
      data
    ) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/material/material-not-in-inventory/id=${id}?department_id=${departmentId}&deskroom_id=${deskroomId}`,
            data
          )
          .then((response) => {
            console.log('here', response.data)
            commit("FETCH_ASSIGNED_MATERIALS", response.data.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    globalMaterialsFilter({ commit }, { type_id, state_id, search }) {
      const data = {
        search: search,
        type_id: type_id,
        state_id: state_id
      };


      return new Promise((resolve, reject) => {
        axios
          .get('/material/global-filter', { params: data })
          .then((response) => {
            resolve(response);
            commit("FETCH_MATERIALS", response.data.data);
          })
          .catch((error) => reject(error));
      });
    },

    globalMaterialEmployeeFilter(
      { commit },
      { employeeId, departmentId, deskroomId, materialId, stateId, oldEmployee, reference, withOld }
    ) {
      const data = {
        employee_id: employeeId,
        old_employee: oldEmployee,
        material_id: materialId,
        state_id: stateId,
        department_id: departmentId,
        deskroom_id: deskroomId,
        reference: reference,
        with_old: withOld
      };

      return new Promise((resolve, reject) => {
        axios
          .get('/material/find', { params: data })
          .then((response) => {
            resolve(response);
            commit("FETCH_ASSIGNMENTS", response.data);
            commit(
              "FETCH_MATERIALS_EMPLOYEES_WITH_OLD_EMPLOYEE",
              response.data.data
            );
          })
          .catch((error) => reject(error));
      });
    },

    allEmployeesUsingMaterials({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/material/using-materials", data)
          .then((response) => {
            commit("FETCH_EMPLOYEES_MATERIALS", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    allMaterialsEmployeeWithOldEmployee({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/material/materials-employees", data)
          .then((response) => {
            commit(
              "FETCH_MATERIALS_EMPLOYEES_WITH_OLD_EMPLOYEE",
              response.data
            );
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteEmployeeMaterial: function (commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/material/delete-using-materials/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchMaterialAccessories: function ({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/material/${id}/accessories`)
          .then((response) => {
            resolve(response);
            commit("FETCH_ACCESSORIES", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    assignMaterial({ commit }, formData) {
      // formData.append("characts", JSON.stringify(formData.get('characts')));
      return new Promise((resolve, reject) => {
        axios
          .post("employee/assign", formData)
          .then((response) => {
            commit("SET_ASSIGN_DATA_UPDATING", true)
            commit("ADD_EMPLOYEES_MATERIALS", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    createMaterial({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/material/create", formData)
          .then((response) => {
            commit("ADD_MATERIAL", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateMaterial(commit, formData) {
      formData.append("_method", "PUT");
      return new Promise((resolve, reject) => {
        axios
          .post(`/material/update/${formData.get("id")}`, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateStockMaterial(commit, formData) {
      let material = JSON.parse(formData.get('material'))
      return new Promise((resolve, reject) => {
        axios
          .post(`/stock/material/${material.id}/update`, formData)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    showMaterial: function (commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/material/show/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    deleteMaterial(commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/material/delete/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allMaterialByType: function ({ commit }, id, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/material/allbytype/${id}`, data)
          .then((response) => {
            commit("FETCH_MATERIALS", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    allMaterialsByState({ commit }, id, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/material/allbystate/${id}`, data)
          .then((response) => {
            resolve(response);
            commit("FETCH_MATERIALS", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    exportPdf() {
      return new Promise((resolve, reject) => {
        axios
          .get("/material/export/pdf")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    exportAssignmentsToPdf() {
      return new Promise((resolve, reject) => {
        axios
          .get(`/material/export_assignments/pdf`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    historyMaterial({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/material/${id}/historique`)
          .then((response) => {
            commit("GET_MATERIAL_HISTORY", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    showMovement(commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/material/showMouvement/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    exportAssignmentsToExcel() {
      return new Promise((resolve, reject) => {
        axios
          .get(`/material/export_assignments/excel`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getMaterialTicket(commit, material_id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`stock/material/ticket/${material_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },

    getAllMaterialTickets(context) {
      const data = context.state.assignments.data;
      let ids = data.map(item => item.id);
      ids = ids.join(',')
      return new Promise((resolve, reject) => {
        axios
          .get(`stock/material/tickets?data=${ids}`)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },

    exportExcel() {
      return new Promise((resolve, reject) => {
        axios
          .get(`/material/export/excel`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getLastMaterialUser({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/material/${id}/last-user`)
          .then((response) => {
            commit("GET_LAST_MATERIAL_USER", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    allAssignments({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/material/assignments", data)
          .then((response) => {
            resolve(response);
            commit("FETCH_ASSIGNMENTS", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    historyFilter({ commit }, { materialId, employeeId, stateId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/material/filter-history/?material_id=${materialId}&employee_id=${employeeId}&state_id=${stateId}`
          )
          .then((response) => {
            resolve(response);
            // commit("FETCH_ASSIGNMENTS", response.data);
            commit("GET_MATERIAL_HISTORY", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    exportMaterialHistoryToPdf(commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/material/export-history/${id}/pdf`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getMaterialTypeAccessories(commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/employee/type-accessories/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getMaterialAccessories({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/material/accessories/${id}`)
          .then((response) => {
            resolve(response);
            commit("FETCH_MATERIAL_ACCESSORIES", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    attachAccessories({ commit }, formData) {
      // formData.append("characts", JSON.stringify(formData.get('characts')));
      return new Promise((resolve, reject) => {
        axios
          .post("employee/attach-accessories", formData)
          .then((response) => {
            commit(`ADD_EMPLOYEES_MATERIAL_ACCESSORIES`, response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fetchMaterialAmortizationPlan({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/material/${id}/amortization-plans`)
          .then((response) => {
            resolve(response);
            commit("FETCH_MATERIAL_AMORTIZATION_PLAN", response.data);
          })
          .catch((error) => reject(error));
      });
    },

    fetchMaterialStatistic(commit, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/material/${id}/stats`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
