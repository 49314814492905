import axios from "../../libs/axios";

export default {
  namespaced: true,

  state: {
    acquisitionTypes: [],
  },

  getters: {},

  mutations: {
    ADD_ACQUISITIONTYPE(state, data) {
      state.acquisitionTypes.push(data);
    },

    FETCH_ACQUISITIONTYPES(state, data) {
      state.acquisitionTypes = data;
    },
  },

  actions: {
    allAcuisitionType({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/acquisition-type/all", data)
          .then((response) => {
            commit("FETCH_ACQUISITIONTYPES", response.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },


    createAcuisitionType({ commit }, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/acquisition-type/create", formData)
          .then((response) => {
            resolve(response);
            commit("ADD_ACQUISITIONTYPE", response.data);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
