import axios from '../../libs/axios';

export default {
  namespaced: true,

  state: {
    amortization_types: [],
    type: {},
  },

  getters: {},

  mutations: {

    FETCH_TYPES(state, data) {
      state.amortization_types= data;
    },

  },

  actions: {
    getAllAmortizationTypes: function ({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/amortizations-types", data)
          .then((response) => {
            commit("FETCH_TYPES", response.data.data);
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },
  },
};
